import React                 from 'react';
import { Flex, Box, Center } from '@chakra-ui/react';
import Img                   from 'gatsby-image';
import { graphql }           from 'gatsby';

import Heading       from '@interness/web-core/src/components/text/Heading/Heading';
import HeaderMedia   from '@interness/theme-default/src/components/HeaderMedia';
import Wrapper       from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import { findMedia } from '@interness/web-core/src/components/_helpers';
import Spacer        from '@interness/web-core/src/components/structure/Spacer/Spacer';

import Subnavigation from '../components/Subnavigation';

const EuroboxenPage = (props) => {
  return (
    <>
      <HeaderMedia id="produkte"/>
      <Subnavigation title="Produkte" menu={[
        {
          'displayName': 'Fertigung',
          'path': '/fertigung'
        },
        {
          'displayName': 'Europaletten',
          'path': '/europaletten'
        },
        {
          'displayName': 'Paletten 1/2/3 weg',
          'path': '/paletten-123-weg'
        },
        {
          'displayName': 'Sonderpaletten',
          'path': '/sonderpaletten'
        },
        {
          'displayName': 'Chemiepaletten',
          'path': '/chemiepaletten'
        },
        {
          'displayName': 'Euro-Gitterboxen',
          'path': '/euro-gitterboxen'
        },
        {
          'displayName': 'Vermietung / Reparaturen',
          'path': '/vermietung-reparaturen'
        }
      ]}/>
      <Spacer/>
      <Wrapper>
        <Heading>Euro-Gitterboxen</Heading>
        <Center textAlign='center'>
          Bundesweite Vermietung einschließlich Zustellservice EURO-Gitterbox 800 x 1200 x 790 mm
          Gitterboxpalette nach UIC-Merkblatt 435-3 für den euröpaischen Palettenpool
        </Center>
        <Spacer/>

        <Flex flexWrap="wrap">
          <Box width={['100%', '100%', '40%', '40%']}>
            <h3>EURO-Gitterboxen</h3>
            <p>Kennzeichen und Qualitätsmerkmale</p>
            <ul>
              <li>Stahlrahmen-Konstruktion und Bodenrahmen mit Füßen.</li>
              <li>Drei feste durchbrochene Wände, eine vierte Wand, bestehend aus zwei um eine Mittelachse um 180" drehbaren, durchbrochenen Klappen- sowie Steilwinkelaufsatz.</li>
              <li>Gitterboxpaletten haben einen Holzboden, jedoch keinen Deckel.</li>
              <li>Blechtafel auf der rechten Seite für die Kreideanschriften und zum Ankleben der Zettel.</li>
            </ul>
            <p>Blechtafel auf der linken Seite mit folgenden Aufschriften :</p>
            <ul>
              <li>EUR-Zeichen</li>
              <li>Bahnzeichen (z.B. DB)</li>
              <li>Kennbuchstaben "Y"</li>
              <li>Immatrikulationsnummer</li>
              <li>Eigengewicht in Kilogramm</li>
              <li>Lastgrenze (z.B. max.1500 kg)</li>
              <li>Laderaum: 0,75 m3.</li>
            </ul>
          </Box>
          <Box width={['100%', '100%', '60%', '60%']} px={6}>
            <Center>
              <Img fixed={findMedia(props.data.collection.images, 'pg-gitterbox-palette').childImageSharp.fixed}/>
            </Center>
          </Box>
        </Flex>
      </Wrapper>
      <Spacer/>
    </>
  )
};

export default EuroboxenPage;


export const query = graphql`
    query {
        collection: directusMediaCollection(name: {eq: "gitterboxen"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fixed {
                                ...GatsbyImageSharpFixed
                            }
                        }
                    }
                }
            }
        }
    }
`;